import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

import './assets/styles/index.css';
import vuetify from './plugins/vuetify';

import VueFilter from 'vue-filter';
Vue.use(VueFilter);

Vue.config.productionTip = false

import * as GmapVue from 'gmap-vue'

import ColorHelper from "./components/ColorHelper"
Vue.use(ColorHelper);

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyBNI56-kNur_S8NWTC4LCzr7FxupwttWYQ',
    libraries: '', // This is required if you use the Autocomplete plugin
  },
  installComponents: true
})


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
