import firebase from "firebase";

var config = {
  apiKey: "AIzaSyA3rm-H7zQGbwFDNz5VxmRAgQAVEWQ1ZZQ",
  authDomain: "raketeahoi-quiz.firebaseapp.com",
  projectId: "raketeahoi-quiz",
  storageBucket: "raketeahoi-quiz.appspot.com",
  messagingSenderId: "977943271646",
  appId: "1:977943271646:web:ae17314ea38666cd18ca4f",
  measurementId: "G-DG1272Q4JW",
  databaseURL: "https://raketeahoi-quiz.firebaseio.com",
};

firebase.initializeApp(config);

// const db = firebaseApp.firestore();
export default firebase;
