<template>
  <v-app class="custom">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({}),
};
</script>

<style lang="scss">
body,
* {
  font-family: "Amatic SC", cursive !important;
}
body,
html {
  font-size: 30px;
}

h1 {
  line-height: 1.25em;
  font-size: 1.5em;

  @media (max-width: 800px) {
    font-size: 1em;
  }
}

.v-btn {
  font-weight: 600 !important;

  &.v-size--default {
    font-size: 0.825em !important;
  }

  &.v-size--small {
    font-size: 0.725em !important;
  }
}

$body-font-family: "Amatic SC";
$title-font: "Amatic SC";

.custom {
  .v-application {
    font-family: $body-font-family, sans-serif !important;
    .title {
      // To pin point specific classes of some components
      font-family: $title-font, sans-serif !important;
    }

    .headline {
      font-family: "Amatic SC" !important;
    }
  }
}

.v-application h1.display-1,
h1.display-1 {
  font-family: $title-font !important;
  font-weight: 600;
}

.v-toolbar__title {
  font-size: 1em !important;
}

input,
.v-input input {
  max-height: inherit !important;
}

.custom {
  .display-title {
    font-size: 1.125em;
    font-weight: 600;
  }
  .v-toolbar__title {
    font-weight: 600;
  }
  .v-text-field__slot input,
  .v-text-field__slot textarea {
    font-size: 2em;
    padding: 20px 0 20px;
    max-height: inherit !important;
  }
  .v-label {
    font-size: 1.4em;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
