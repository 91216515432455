<template>
  <v-container fill-height fluid class="x-ctn">
    <v-row align="center" justify="center">
      <div class="text-center pa-2">
        <img
          :src="require(`@/assets/images/rakete-ahoi.svg`)"
          class="logo mb-5"
        />
        <h1 class="mb-10">Halli hallo & herzlich willkommen.</h1>

        <p>Hast du schon vom Kollegen einen Code bekommen?</p>

        <v-alert
          border="left"
          colored-border
          color="red"
          elevation="2"
          v-if="errorMessage"
        >
          {{ errorMessage }}
        </v-alert>

        <input type="text" v-model="code" class="code-input mb-2" />

        <v-btn class="custom" @click="joinQuiz">Beitreten</v-btn>

        <v-divider class="mt-10 mb-10" />

        <p>
          Oder willst du ein neues Quiz erstellen?
        </p>

        <v-btn primary class="custom" @click="createQuiz">
          Neues Quiz erstellen
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "../Firebase";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      ref: firebase.firestore(),
      code: "",
      errorMessage: undefined,
    };
  },
  methods: {
    async joinQuiz() {
      this.ref
        .collection("quizes")
        .doc(this.code.toLowerCase().trim())
        .get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            this.$router.push(`/play/${this.code.toLowerCase().trim()}`);
          } else {
            this.errorMessage = "Es scheint als gäbe es das Spiel nicht?!";
          }
        });
    },

    createQuiz() {
      const gameId = Math.random()
        .toString(36)
        .substr(2, 6);
      localStorage.setItem(`rakete-ahoi-quizes-created-${gameId}`, true);
      this.ref
        .collection("/quizes")
        .doc(gameId)
        .set({ name: "", players: [], questions: [], teams: [] })
        .then(() => {
          this.$router.push(`/admin/${gameId}`);
        });
    },
  },

  watch: {
    code() {
      this.errorMessage = undefined;
    },
  },
};
</script>

<style scoped>
.logo {
  max-width: 85%;
}

.code-input {
  border: 1px solid #b9b9b9;
  display: block;
  border-radius: 5px;
  margin: 0 auto;
  text-align: center;
  font-weight: 600;
}
</style>
