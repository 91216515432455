export default {
  install(Vue) {
    Vue.prototype.$allColors = () => {
      return [
        "red",
        "pink",
        "purple",
        "deep-purple",
        "indigo",
        "blue",
        "light-blue",
        "cyan",
        "teal",
        "green",
        "light-green",
        "lime",
        "yellow",
        "amber",
        "orange",
        "deep-orange",
        "brown",
        "blue-grey",
      ];
    };
  
    Vue.prototype.$isDarkColor = (color) => {
      const darkThemedColors = ["indigo", "purple", "red", "pink", "teal", "green", 'deep-purple'];
      return darkThemedColors.includes(color);
    }; 
  }
}
